<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title float-left">Denied Applications</h5>
            <div class="float-right">
              <v-btn
                class="ma-2 mr-3"
                tile
                outlined
                color="alert"
                @click="approveSelected"
              >
                Approve
              </v-btn>
            </div>
          </div>

          <div class="container">
            <b-table striped hover :items="declinedList" :fields="fields">
              <template #cell(select)="data">
                <span class="custom-checkbox">
                  <input
                    v-model="selected"
                    type="checkbox"
                    :value="data.item.id"
                  />
                </span>
              </template>
              <template #cell(id)="data">
                {{ data.item.id }}
              </template>
              <template #cell(seller)="data">
                {{ data.item.seller_company.business_profile.company_name }}
              </template>
              <template #cell(contact)="data">
                {{ data.item.seller_company.business_profile.email }}
              </template>
              <template #cell(date)="data">
                {{ data.item.updated_at | date_format }}
              </template>
              <template #cell(status)="data">
                {{ data.item.status }}
                <template
                  v-if="data.item.reason != null && data.item.reason != ''"
                >
                  - {{ data.item.reason }}
                </template>
              </template>
              <template #cell(actions)="data">
                <!-- <a :data-id="data.item.id" href="#deleteEmployeeModal" class="deny" data-toggle="modal"><i class="material-icons" data-toggle="tooltip" title="Deny">&#xe5c9;</i></a>
            <a href="#editEmployeeModal" class="edit" data-toggle="modal"><i class="material-icons" data-toggle="tooltip" title="Edit">&#xE254;</i></a>
            <a href="#deleteEmployeeModal" class="approve" data-toggle="modal"><i class="material-icons" data-toggle="tooltip" title="Approve">&#xe174;</i></a> -->
                <v-btn
                  medium
                  :data-id="data.item.id"
                  class="ma-2 mr-3"
                  tile
                  outlined
                  color="alert"
                  @click="approve(data.item.id)"
                >
                  Approve
                </v-btn>
                <v-btn
                  medium
                  class="ma-2"
                  tile
                  outlined
                  color="success"
                  @click="
                    $router.push('/crm/applications/detail/' + data.item.id)
                  "
                >
                  View
                </v-btn>
              </template>
            </b-table>
            <div class="row">
              <b-pagination
                v-model="declinedCurrentPage"
                :total-rows="declinedListCount"
                :per-page="limit"
                style="margin: 0 auto;"
              ></b-pagination>
              <b-modal
                v-model="modalShowGreeting"
                centered
                title="Approve application?"
                @ok="handleOkGreeting"
              >
                <div class="d-block">
                  <p>Introduce a Note:</p>
                  <b-form-input
                    v-model="greeting"
                    placeholder="Add a comment"
                  ></b-form-input>
                </div>
              </b-modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import moment from 'moment'
import { mapActions } from 'vuex'
import DeniedApplications from '@/graphql/queries/DeniedApplications.gql'
import ApprovedPartner from '@/graphql/mutations/ApprovedPartner.gql'

export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
      }
    },
  },
  data() {
    return {
      applicationId: '',
      greeting: '',
      modalShowGreeting: false,
      selected: [],
      declinedCurrentPage: 1,
      declinedListCount: 0,
      limit: 5,
      declinedList: [],
      fields: [
        {
          key: 'select',
          label: '',
        },
        {
          key: 'id',
          label: 'Document#',
        },
        {
          key: 'seller',
          label: 'Partner Name',
        },
        {
          key: 'contact',
          label: 'Contact',
        },
        {
          key: 'date',
          label: 'Date of Application',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'actions',
          label: 'Actions',
        },
      ],
    }
  },
  apollo: {
    declinedList: {
      query: DeniedApplications,
      variables() {
        return {
          status: 'denied',
          supplier_company: this.$store.state.auth.user.company_relation,
          limit: this.limit,
          start: (this.declinedCurrentPage - 1) * this.limit,
        }
      },
    },
  },
  mounted() {
    setTimeout(() => {
      this.getApplicationTotalCount({
        params:
          'status=declined&supplier_company=' +
          this.$store.state.auth.user.company_relation,
      }).then((res) => {
        this.declinedListCount = res.data
      })

      this.$apollo.queries.declinedList.refetch()
    }, 1000)
  },
  methods: {
    ...mapActions([
      'getSupplierApplications',
      'getApplicationTotalCount',
      'updateApplication',
      'getContactInfo',
      'getContactTotalCount',
      'submitContact',
      'updateContact',
      'submitCrm',
      'getCrmTotalCount',
    ]),
    approve(id) {
      this.modalShowGreeting = true
      this.applicationId = id
    },
    updatedMsg() {
      Swal.fire({
        title: '',
        text: 'The application has been successfully updated!',
        icon: 'success',
        confirmButtonClass: 'btn btn-secondary',
      })

      location.reload()
    },
    async handleOkGreeting() {
      try {
        await this.$apollo.mutate({
          mutation: ApprovedPartner,
          variables: {
            applicationId: this.applicationId,
            greeting: this.greeting,
          },
        })
        this.updatedMsg()
      } catch (error) {
        this.$bvToast.toast('An Error Occured!', {
          title: 'Error',
          variant: 'danger',
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: true,
        })
      }
    },
    async approveSelected() {
      if (this.selected.length > 0) {
        try {
          await Promise.all(
            this.selected.map(async (id) => {
              await this.$apollo.mutate({
                mutation: ApprovedPartner,
                variables: {
                  applicationId: id,
                },
              })
            })
          )
          Swal.fire({
            title: '',
            text: 'The application has been successfully updated!',
            icon: 'success',
            confirmButtonClass: 'btn btn-secondary',
          })

          location.reload()
        } catch (err) {
          this.$bvToast.toast('An Error Occured!', {
            title: 'Error',
            variant: 'danger',
            toaster: 'b-toaster-bottom-right',
            solid: true,
            appendToast: true,
          })
        }
      }
    },
  },
}
</script>
