<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title float-left">Approved Applications</h5>
            <div class="float-right">
              <v-btn
                smallclass="ma-2 ml-3"
                tile
                outlined
                color="success"
                @click="denySelected"
              >
                Deny
              </v-btn>
            </div>
          </div>

          <div class="container">
            <b-table striped hover :items="approvedList" :fields="fields">
              <template #cell(select)="data">
                <span class="custom-checkbox">
                  <input
                    v-model="selected"
                    type="checkbox"
                    :value="data.item.id"
                  />
                  <label for="checkbox1"></label>
                </span>
              </template>
              <template #cell(id)="data">
                {{ data.item.id }}
              </template>
              <template #cell(seller)="data">
                {{ data.item.seller_company.business_profile.company_name }}
              </template>
              <template #cell(contact)="data">
                {{ data.item.seller_company.business_profile.email }}
              </template>
              <template #cell(date)="data">
                {{ data.item.updated_at | date_format }}
              </template>
              <template #cell(status)="data">
                {{ data.item.status }}
              </template>
              <template #cell(actions)="data">
                <!-- <a :data-id="data.item.id" href="#deleteEmployeeModal" class="deny" data-toggle="modal"><i class="material-icons" data-toggle="tooltip" title="Deny">&#xe5c9;</i></a>
            <a href="#editEmployeeModal" class="edit" data-toggle="modal"><i class="material-icons" data-toggle="tooltip" title="Edit">&#xE254;</i></a>
            <a href="#deleteEmployeeModal" class="approve" data-toggle="modal"><i class="material-icons" data-toggle="tooltip" title="Approve">&#xe174;</i></a> -->
                <v-btn
                  medium
                  class="ma-2"
                  tile
                  outlined
                  color="success"
                  @click="
                    $router.push('/crm/applications/detail/' + data.item.id)
                  "
                >
                  View
                </v-btn>
                <v-btn
                  medium
                  class="ma-2 ml-3"
                  tile
                  outlined
                  color="success"
                  @click="deny(data.item.id)"
                >
                  Deny
                </v-btn>
              </template>
            </b-table>
            <b-modal
              v-model="modalShowDeny"
              centered
              title="Deny application?"
              @ok="handleOkDeny"
            >
              <div class="d-block">
                <p>Introduce a Note:</p>
                <b-form-input
                  v-model="reason"
                  placeholder="Add a comment"
                ></b-form-input>
              </div>
            </b-modal>
            <div class="row">
              <b-pagination
                v-model="approvedCurrentPage"
                :total-rows="approvedListCount"
                :per-page="limit"
                style="margin: 0 auto;"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import moment from 'moment'
import { mapActions } from 'vuex'
import ApprovedApplications from '@/graphql/queries/ApprovedApplications.gql'
import DeniedPartner from '@/graphql/mutations/DeniedPartner.gql'

export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
      }
    },
  },
  data() {
    return {
      reason: '',
      applicationId: '',
      modalShowDeny: false,
      modalShow: false,
      selected: [],
      approvedCurrentPage: 1,
      approvedListCount: 0,
      limit: 5,
      approvedList: [],
      fields: [
        {
          key: 'select',
          label: '',
        },
        {
          key: 'id',
          label: 'Document#',
        },
        {
          key: 'seller',
          label: 'Partner Name',
        },
        {
          key: 'contact',
          label: 'Contact',
        },
        {
          key: 'date',
          label: 'Date of Application',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'actions',
          label: 'Actions',
        },
      ],
    }
  },
  apollo: {
    approvedList: {
      query: ApprovedApplications,
      variables() {
        return {
          status: 'approved',
          supplier_company: this.$store.state.auth.user.company_relation,
          limit: this.limit,
          start: (this.approvedCurrentPage - 1) * this.limit,
        }
      },
    },
  },

  mounted() {
    setTimeout(() => {
      this.getApplicationTotalCount({
        params:
          'status=approved&supplier_company=' +
          this.$store.state.auth.user.company_relation,
      }).then((res) => {
        this.approvedListCount = res.data
      })

      this.$apollo.queries.approvedList.refetch()
    }, 1000)
  },
  methods: {
    ...mapActions([
      'getSupplierApplications',
      'getApplicationTotalCount',
      'getContactInfo',
      'getContactTotalCount',
      'submitContact',
      'updateContact',
      'updateApplication',
    ]),
    deny(id) {
      this.modalShowDeny = true
      this.applicationId = id
    },
    async denySelected() {
      if (this.selected.length > 0) {
        try {
          await Promise.all(
            this.selected.map(async (id) => {
              await this.$apollo.mutate({
                mutation: DeniedPartner,
                variables: {
                  applicationId: id,
                },
              })
            })
          )
          Swal.fire({
            title: '',
            text: 'The application has been successfully updated!',
            icon: 'success',
            confirmButtonClass: 'btn btn-secondary',
          })

          location.reload()
        } catch (err) {
          this.$bvToast.toast('An Error Occured!', {
            title: 'Error',
            variant: 'danger',
            toaster: 'b-toaster-bottom-right',
            solid: true,
            appendToast: true,
          })
        }
      }
    },

    updatedMsg() {
      Swal.fire({
        title: '',
        text: 'The application has been successfully updated!',
        icon: 'success',
        confirmButtonClass: 'btn btn-secondary',
      })

      location.reload()
    },
    async handleOkDeny() {
      try {
        await this.$apollo.mutate({
          mutation: DeniedPartner,
          variables: {
            applicationId: this.applicationId,
            reason: this.reason,
          },
        })
        this.updatedMsg()
      } catch (error) {
        this.$bvToast.toast('An Error Occured!', {
          title: 'Error',
          variant: 'danger',
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: true,
        })
      }
    },
  },
}
</script>
