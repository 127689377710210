<template>
  <div>


    <div class="main " style="background:#fff;">
      <div class="section">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <md-card class="bg-success h-100">
                <md-card-content>
                  <h3 class="card-category card-category-social text-white">
                    <i class="far fa-newspaper" /> Approve Pending Partners
                  </h3>
                  <h4 class="card-title text-white">
                    Approve Partners to link and allow them to purchase.
                  </h4>
                  <p class="card-description text-white">
                    Create the pricing lists you can then assign to your
                    customers. A customer can have 1 price list per channel
                    only. <br />You can also assign a company a price list in
                    the CRM.
                  </p>

                  <div class="card-stats text-center"></div>
                </md-card-content>
              </md-card>
            </div>
            <div class="col-lg-6">
             
            </div>
          </div>

          <v-tabs        background-color="grey lighten-4"
color="teal" class="mt-6">
            <v-tab>
              <v-icon left>
                mdi-account
              </v-icon>
              Pending
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-lock
              </v-icon>
              Approved
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-access-point
              </v-icon>
              Denied
            </v-tab>

            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <Pendingpartners> </Pendingpartners>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <Approvedpartners> </Approvedpartners>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <Deniedpartners> </Deniedpartners>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pendingpartners from "../../../components/Pending-applications.vue";
import Deniedpartners from "../../../components/Denied-applications.vue";
import Approvedpartners from "../../../components/Approved-applications.vue";
export default {
  components: {
    Pendingpartners,
    Deniedpartners,
    Approvedpartners
  }
};
</script>
